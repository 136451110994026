.group-status-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-grey-50, #e7ecee);
  background: var(--shades-white, #fff);

  /* Shadows/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.chevron-vertical {
  height: 15px;
}

.well-controls-button {
  display: flex;
  padding: 8px;
  gap: 4px;
  border: none;
  cursor: pointer;
  position: relative;
  border-radius: 8px;
}

.group-status-icons-group .tooltip-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-and-badge {
  padding: 20px 24px 0px 24px;
  align-items: flex-start;
  gap: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
}

.group-status-full-screen-icon {
  display: flex;
  stroke: var(--primary-indigo-700, #005f7c);
}

.text {
  flex: 1 0 0;
  color: var(--neutral-grey-800, #191f23);
  /* Paragraph 3 [P3]/Semibold */
  font-family: Mulish;
  font-size: 18px;
  font-style: normal;
  font-weight: var(--font-weight-bolder);
  line-height: 28px;
  /* 155.556% */
}

.divider {
  display: flex;
  height: 1px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: var(--neutral-grey-100, #d0d8dd);
}

.group-status-sub-header {
  display: flex;
  padding: 0px 24px;
  justify-content: space-between;
  align-self: stretch;
}

.tabs {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.options {
  display: flex;
  height: 44px;
  gap: 8px;
}

.group-status-icons-group {
  display: flex;
  gap: 8px;

  .custom-switch-handle {
    height: 1.25rem !important;
    width: 1.25rem !important;
  }
}

/* Table container */
.table-container {
  padding: 0 1rem 1rem 0rem;
  width: -webkit-fill-available;
  display: inline-block;
  border-radius: 4px;
  border: 1px solid var(--neutral-grey-100, #d0d8dd);
  margin: 2px 24px;

  &.ui {
    padding-right: 0;
    padding-bottom: 0;
    margin-bottom: 15px;
  }

  .groupTable.sticky>div>div {
    border-radius: 8px;
  }
}

.well {
  border-right: 1px solid var(--neutral-grey-100, #d0d8dd);
}

.groupTable {
  width: -webkit-fill-available;
  overflow: hidden;
  // max-width: 100%;
}

.tableHeader {
  position: sticky;
  z-index: 1;
  width: fit-content;
}

.row {
  padding: 16px 8px;
  // position: static !important;
}

.tableHeader {
  z-index: 4;
  top: 0;
}

.tr:last-child .td {
  border-bottom: 0;
}

/* Table header cell */
.th,
.td {
  padding: 0 0 5px 5px;
  overflow: hidden;
  text-align: left;
}

/* Table header */
.table-header-cell {
  // height: 85px;
  align-items: center;
  border-radius: 4px 0px 0px 0px;
  border-bottom: 1px solid var(--neutral-grey-100, #d0d8dd);
}

/* Table header item */
.table-header-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: 20px;
  font-family: Mulish;
  font-size: 14px;
  font-weight: var(--font-weight-medium);
  line-height: 20px;
  letter-spacing: 0em;
  height: 100%;
  padding: 12px 8px;
  //text-align: center;
}

/* Table row name */
.table-row-name {
  border-bottom: 1px solid var(--neutral-grey-100, #d0d8dd);
  border-right: 1px solid var(--neutral-grey-100, #d0d8dd);
  display: flex !important;
  min-width: 180px;
}

.group-status-tooltip {
  position: absolute;
}

.table-row-name:hover {
  background: var(--dark-mode-800, #253040) !important;
}

.table-row-name:hover {
  background: var(--dark-mode-800, #253040) !important;
}

.table-row {
  cursor: pointer;
  display: flex !important;
}

/* Table row link */
.table-row-link {
  color: var(--Primary-Indigo-400, #60bfda);
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  padding-top: 5px;
}

/* Highlighted row */
.highlighted {
  background-color: #dcf1f7;
}

/* Table row alarms */
.table-row-alarms {
  padding: 16px 8px;
  border-bottom: 1px solid var(--neutral-grey-100, #d0d8dd);
}

/* Alarm badges */
.alarm-badges {
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
  //max-width: 150px;
}

/* Badge */
.badge {
  display: flex;
  padding: 2px 10px;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--error-red-700, #b42318);
  background: var(--error-red-50, #fef3f2);
}

/* Badge text */
.badge-text {
  height: 20px;
  font-family: Mulish;
  font-size: 14px;
  font-weight: var(--font-weight-bolder);
  line-height: 20px;
  text-align: center;
  color: #b42318;
}

/* Sort image */
.sort-image {
  vertical-align: middle;
}

.group-status-main-header {
  display: flex;
  height: 72px;
  padding: 12px 32px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background: var(--Shades-White, #fff);
}

.group-status-group-name {
  color: var(--Neutral-Grey-600, #4b5e68);
  font-size: 23px;
  font-weight: var(--font-weight-bold);
  line-height: 28px;
  letter-spacing: -0.92px;
}

.group-status-widget-container {
  padding: 1rem 1.5rem;
}

.table-view-ddn {
  padding-left: 8px;
}

.well-table {

  th:first-child,
  td:first-child {
    padding-left: 1rem;
  }
}

.theme-dark {
  .well-table {

    th,
    td {
      color: #f7f9f9 !important;
    }

    td a.table-row-link {
      color: #60bfda;
    }
  }

  .group-status-main-header {
    background-color: #131f2e;
    color: #fff;
  }

  .group-status-widget-card {
    background-color: #1e2b3a;
    border-color: #424d5c;
    background-image: linear-gradient(#1e2b3a 20%, 50%, #0f1d2e 90%);
  }

  .card-header-divider {
    background: #424d5c;
  }

  .widget-header-text,
  .group-status-group-name {
    color: #fff;
  }

  .group-status-sub-header {
    .options .download-btn {
      background: transparent;
    }

    .options.ui {
      height: auto;

      svg {
        height: 20px;
        width: 20px;
      }

      span,
      span.custom-switch-handle {
        height: 20px;
        width: 20px;
      }

      .dropdown .dropdown-btn {
        height: 40px;

        span {
          height: auto;
          width: auto;
        }
      }

      .custom-switch {
        span.custom-switch-handle {
          height: 20px;
          width: 20px;
        }
      }

      .control-actions-content {
        span {
          height: auto;
          width: auto;
        }
      }

      .table-view-ddn {
        margin-bottom: 0;
        border-left: 1px solid #a0b1bb;
      }
    }

    .options .download-btn:hover {
      background: var(--dark-mode-800, #253040) !important;
    }

    .options .well-controls-button:hover {
      background: var(--dark-mode-800, #253040) !important;
    }

    .options .card-viewer-button:hover {
      background: var(--dark-mode-800, #253040) !important;
    }
  }

  .highlighted {
    background: #253040 !important;
  }

  .group-status-no-assets-block {
    h6 {
      font-size: 19px;
      line-height: 20px;
    }

    p {
      color: #b8c5cc;
      line-height: 20px;
    }
  }
}

.group-status-no-assets-block {
  max-width: 266px;
}

.group-status-no-padding {
  padding-left: 0px;
  padding-top: 0;
}

.group-status-box-wrapper {
  padding: 40px;
  border-radius: 8px;
  padding-top: 0px;
}

.group-status-full-screen {
  position: fixed;
  z-index: 10001;
  inset: 0px;
  overflow-y: auto;
  margin: auto !important;
  height: 609px;
  width: 1000px;
  border: 1px solid #4a5463;
  border-radius: 8px;
}

.group-status-card-viewer-button {
  display: flex;
  padding: 0.25rem;
  padding-right: 0.1rem;
  align-items: center;
  gap: 0.25rem;
  background: none;
  border: none;
}

.table-view-ddn .item-selected {
  background: transparent !important;
}

.table-row-value:hover {
  background: var(--dark-mode-800, #253040) !important;
}

.table-header-item:hover {
  background: var(--dark-mode-800, #253040) !important;
}

.group-status-loader .loader {
  margin-left: 670px !important;
}

[data-sticky-td] {
  position: sticky;
}

.theme-dark .table-row-general {
  padding: 12px 8px !important;
}

.theme-dark .tr.table-row:hover>div,
.theme-dark .tr.table-row.highlighted>div {
  background-color: #253040;
}

.tr.table-row .table-row-link:hover {
  text-decoration: underline;
}

.theme-dark .tr.table-row.highlighted>div:first-child {
  border-left: 3px solid #dc4405;
}