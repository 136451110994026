.dropdown {
  width: 320px;
  position: relative;
  font-size: 18px;
  font-weight: 500;
  color: #191f23;
}

.dropdown .dropdown-btn {
  display: flex;
  padding: 0px 14px;
  height: 46px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--neutral-grey-100, #d0d8dd);
  background: var(--shades-white, #fff);
  justify-content: space-between;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: pointer;
}

.fa-caret-down {
  content: url('../../../images/chevron-down.svg');
  text-align: right;
  direction: rtl;
}

.fa-caret-up {
  content: url('../../../images/chevron-up.svg');
  text-align: right;
  display: block;
}

.fa-eye {
  content: url('../../../images/eye.svg');
  text-align: right;
  direction: rtl;
  width: 20px;
  height: 20px;
}

.fa-eye {
  display: flex;
}

.dropdown-content {
  display: flex;
  align-items: center;
  flex: 1 0 0;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid var(--neutral-grey-50, #e7ecee);
  background: var(--shades-white, #fff);
  position: absolute;
  width: 320px;
  box-shadow:
    0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  max-height: 500px;
  overflow-y: auto;
  overflow-wrap: break-word;
  font-weight: normal;
}

.theme-dark {
  .dropdown-content {
    background: var(--darkmode-700, #131f2e);
    color: #f7f9f9;
    border-color: #4a5463;

    .item-selected {
      display: flex;
      background-color: #4a5463;
      justify-content: space-between;
    }

    .check-icon {
      display: flex;
      width: 20px;
      height: 20px;
      justify-content: center;
      align-items: center;
    }
  }
}

.dropdown-content .item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-content .item:hover {
  background: #458ca2;
}

.dropdown-content .item-selected {
  padding: 10px;
  cursor: pointer;
  background: #dcf1f7;
}

.fa-text {
  padding-left: 10px;
}
