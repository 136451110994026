.esp-pressure-profile-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;

  .error-message {
    text-align: center;
  }
}

.esp-pressure-profile-graphic-container {
  flex: 1;
  // background-color: yellow;
}

.esp-pressure-profile-legend-container {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.esp-pressure-profile-legend-container-column {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.esp-pressure-profile-legend-item-container {
  display: flex;
  flex-direction: row;
  font-size: 9px;
}

.esp-pressure-profile-legend-item-label-container {
  display: flex;
}

.esp-pressure-profile-legend-item-label {
  white-space: preserve nowrap;
}

.esp-pressure-profile-legend-item-value-container {
  display: flex;
}

.esp-pressure-profile-legend-item-value {
  white-space: preserve nowrap;
}

// Wellbore Images and Positioning

// Flow Direction

.esp-pressure-profile-flow-direction-svg-container {
  position: absolute;
  top: 65px;
  left: 33px;
}

// Head Out 1

.esp-pressure-profile-head-out-1-svg-container {
  position: absolute;
  top: 62px;
  left: 53px;
}

// Head Out 2

.esp-pressure-profile-head-out-2-svg-container {
  position: absolute;
  top: 74px;
  left: 53px;
}

// Well Head

.esp-pressure-profile-well-head-image {
  width: 65px;
  height: 18px;
  border-width: 2px;
  border-style: solid;
  border-color: black;
  background: linear-gradient(#bbbbbb, #5f5f5f);
}

.esp-pressure-profile-well-head-image-container {
  position: absolute;
  top: 85px;
  left: 47px;
}

// Perforations

.esp-pressure-profile-perforation-depth-value {
  text-align: center;
  width: 45px;
  font-size: 9px;
  font-weight: bold;
}

.esp-pressure-profile-perforation-depth-label {
  padding-left: 3px;
  font-size: 12px;
  font-weight: bold;
}

.esp-pressure-profile-perforations-image {
  width: 65px;
  height: 18px;
  border-width: 2px;
  border-style: solid;
  border-color: black;
  background: linear-gradient(#bbbbbb, #5f5f5f);
}

.esp-pressure-profile-perforations-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  position: absolute;
  top: 270px;
  left: 2px;
  white-space: pre-line;
}

// Casing

.esp-pressure-profile-casing-image {
  width: 30px;
  height: 225px;
  border-width: 2px;
  border-style: solid;
  border-color: black;
  background-color: #ffffff;
}

.esp-pressure-profile-casing-image-container {
  position: absolute;
  top: 80px;
  left: 65px;
}

// Casing Plug

.esp-pressure-profile-casing-plug-image {
  width: 30px;
  height: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: black;
  background: linear-gradient(#bbbbbb, #5f5f5f);
}

.esp-pressure-profile-casing-plug-image-container {
  position: absolute;
  top: 295px;
  left: 65px;
}

// Casing Left

.esp-pressure-profile-casing-left-top-container {
  position: absolute;
  top: 74px;
  left: 74px;
}

// Casing Right

.esp-pressure-profile-casing-right-top-container {
  position: absolute;
  top: 64px;
  left: 85px;
}

// Tubing

.esp-pressure-profile-tubing-image {
  width: 12.5px;
  height: 200px;
  background-color: #ffffff;
}

.esp-pressure-profile-tubing-image-container {
  position: absolute;
  top: 80px;
  left: 74px;
}

// Dynamic Level Line

.esp-pressure-profile-dynamic-level-line-svg-container {
  position: absolute;
  top: 187px;
  left: 61px;
}

// Dynamic Level Text

.esp-pressure-profile-dynamic-level-text {
  text-align: center;
  font-weight: bold;
  font-size: 12px;
}

.esp-pressure-profile-dynamic-level-text-container {
  position: absolute;
  top: 168px;
  left: 7px;
}

// Fluid Level Text
.esp-pressure-profile-fluid-level-text {
  text-align: center;
  font-size: 9px;
  font-weight: bold;
}

.esp-pressure-profile-fluid-level-text-container {
  position: absolute;
  top: 185px;
  left: 108px;
}

// Well Depth Axis

.esp-pressure-profile-well-depth-axis-container {
  position: absolute;
  top: 60px;
  left: 160px;
}

// Well Depth Axis Label

.esp-pressure-profile-well-depth-axis-label {
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  transform: rotate(-90deg);
}

.esp-pressure-profile-well-depth-axis-label-container {
  position: absolute;
  top: 130px;
  left: 118px;
}

// Pressure Axis

.esp-pressure-profile-pressure-axis-container {
  position: absolute;
  top: 64px;
  left: 156px;
}

// Pressure Axis Label

.esp-pressure-profile-pressure-axis-label {
  text-align: center;
  font-weight: bold;
  font-size: 12px;
}

.esp-pressure-profile-pressure-axis-label-container {
  position: absolute;
  top: 46px;
  left: 255px;
}

// Dynamic Pump Depth

.esp-pressure-profile-dynamic-pump-depth-container {
  .pump-icon-container {
    position: absolute;
    top: 182px;
    left: 74px;
  }

  .pump-icon {
    width: 12.4px;
    height: 12.4px;
    background-color: black;
  }

  .pump-depth-horizontal-line-container {
    position: absolute;
    top: 187.5px;
    left: 74px;
  }

  .pump-depth-label-container {
    position: absolute;
    top: 172px;
    left: 101px;
  }

  .pump-depth-label {
    text-align: center;
    font-weight: bold;
    font-size: 9px;
    transform: translateY(-15px);
  }

  .pump-depth-value-container {
    position: absolute;
    top: 185px;
    left: 108px;
  }

  .pump-depth-value {
    text-align: center;
    font-weight: bold;
    font-size: 9px;
    transform: translateY(15px);
  }

  .casing-left-container {
    position: absolute;
    top: 79px;
    left: 74px;
  }

  .casing-right-container {
    position: absolute;
    top: 79px;
    left: 85px;
  }

  .gradient-box-container {
    position: absolute;
    top: 154px;
    left: 321px;
  }

  .gradient-box {
    width: 36px;
    height: 35px;
    border-width: 2px;
    border-style: solid;
  }

  .pip-point-container {
    position: absolute;
    top: 179px;
    left: 192.9px;

    .pip-point-label {
      position: relative;
      font-weight: bold;
      font-size: 12px;
      color: #40933c;
      left: 2px;
      top: 10px;
    }

    .pip-left-arrow-container {
      position: relative;
      top: -13.7px;
      left: -11px;
    }

    .pip-left-arrow-point {
      position: relative;
      top: -28.6px;
      left: -25px;
    }

    .pip-flowing-gradient-delta-value {
      position: absolute;
      font-weight: bold;
      font-size: 12px;
      color: #ffa200;
      left: 0px;
      top: -10px;
      text-wrap: nowrap;
      // background-color: rgba(255, 255, 255, 0.898);
    }
  }

  .pdp-point {
    position: absolute;
    top: 178px;
    left: 346.3px;
  }

  .pdp-label {
    position: absolute;
    font-weight: bold;
    font-size: 12px;
    color: #648cb4;
    left: 360px;
    top: 190px;
  }

  .pump-friction-value {
    position: absolute;
    font-weight: bold;
    font-size: 12px;
    color: #4bbdde;
    left: 326px;
    top: 136px;
  }

  .pip-right-arrow-container {
    position: absolute;
    top: 182.9px;
    left: 342px;
    transform: rotate(180deg);
  }

  .psp-point {
    position: absolute;
    top: 178px;
    left: 312px;
  }

  .psp-label {
    position: absolute;
    font-weight: bold;
    font-size: 12px;
    color: #b93434;
    left: 326px;
    top: 190px;
  }
}

.esp-pressure-profile-wellbore-graphic-container {
  position: relative;
  left: 0px;

  .fluid-level-to-fbhp-line-container {
    position: absolute;
    top: 188.5px;
    left: 177.7px;
    height: 100.5px;
  }

  .perforation-depth-line {
    position: absolute;
    top: 287.5px;
    left: 94px;
  }

  .fbhp-point {
    position: absolute;
    top: 278.5px;
  }

  .fbhp-label {
    position: absolute;
    top: 290px;
    position: relative;
    font-weight: bold;
    font-size: 12px;
    color: #a922c7;
    left: 0px;
    transform: translateX(222.576px) translateY(15px);
  }

  .casing-head-pressure-label-container {
    position: absolute;
    top: 40px;
    left: 165px;

    .casing-head-pressure-label {
      text-align: center;
      font-weight: bold;
      font-size: 12px;
      transform: rotate(-90deg);
      color: #52dc6b;
    }
  }

  .casing-head-pressure-line-1-container {
    position: absolute;
    top: 65.5px;
    left: 176.8px;
  }

  .well-head-pressure-label-container {
    position: absolute;
    top: 39px;
    left: 179px;

    .well-head-pressure-label {
      text-align: center;
      font-weight: bold;
      font-size: 12px;
      transform: rotate(-90deg);
      color: #dd48ec;
    }
  }

  .static-gradient-line-container {
    position: absolute;
    top: 66px;
    left: 191px;
    width: 130px;
  }

  .flowing-gradient-line-container {
    position: absolute;
    top: 66px;
    left: 191px;
    width: 165px;
  }
}
