.servererror-background {
  width: 725px;
  height: 492px;
  top: 50%;
  left: 50%;
  position: absolute;
  border-radius: 10px;
  padding: 20px;
  background-color: #001023;
  color: #fff;
  margin-top: -250px;
  margin-left: -365px;
}


.servererror-message {
  display: inline-block;
  width: 40%;

  .text-lg {
    font-size: 24px;
    margin: 3rem 0 1.5rem 0;
  }

  p {
    font-size: 16px;
  }

  .copy {
    position: absolute;
    bottom: 1rem;
    font-size: 14px;
    color: #B8C5CC;
  }
}

.servererror-content {
  display: flex;
}

.servererror-logo {
  display: inline-block;
  border-radius: 10px;
}

.servererror-inlineblock {
  display: inline-block;
}

.servererror-xspocmargin {
  margin-top: 165px;
}

.branding {
  width: 240px;
}