.dropdown-label-container {
  padding: 0.8rem;
  border-bottom: 1px solid var(--neutral-grey-50, #e7ecee);
}
.toggle-dropdown-container {
  position: relative;
  display: inline-block;
  text-align: left;
}
.primary {
  color: #00789e;
}

.dropdown-list {
  font-size: 16px;
  line-height: 24px;
  width: 264px;
  padding: 10px 0px 10px 9px;
}
.dropdown-switches {
  margin-top: 0.5rem;
  padding-left: 0.8rem;
}
.option-label {
  color: var(--neutral-grey-800, #191f23);
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: var(--font-weight-bolder);
  line-height: 20px;
}
.toggle-menu-items {
  position: absolute;
  right: 0;
  z-index: 10;
  margin-top: 2;
  width: 56px;
  transform-origin: top-right;
  border-radius: 0.375rem;
  background-color: #ffffff;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border: 1px solid #000000;
  outline: none;
}
