.control-actions-content {
  display: flex;
  align-items: center;
  height: 38px;
  background: var(--dark-mode-600, #253040);
}

.control-actions-content .custom-switch {
  height: auto;
}

.control-actions-nested-list-button {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

.control-actions-content:hover {
  /* background-color: #f7f9f9; */
  border-radius: 6px;
  background: var(--Dark-mode-600, #253040);
}

.icon-container {
  padding-left: 0.8rem;
  margin-right: 28px;
}

.has-icon {
  margin-right: 8px;
}

.bottom-border {
  border-bottom: 1px solid var(--neutral-grey-50, #e7ecee);
}

.control-actions-checkbox {
  margin-left: auto;
  margin-right: 10px;
}

.control-actions-checkbox-item {
  appearance: none;
  width: 1.2rem;
  height: 1.2em;
  border: 0.14em solid #647980;
  background: #fff;
  cursor: pointer;
  position: relative;
  border-radius: 4px;
}

.control-actions-checkbox-item:checked::before {
  content: '\2713';
  font-size: 1.2em;
  color: #000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.control-actions-nested-list {
  display: flex;
  flex-direction: column;
  margin-left: 50;
  height: 100%;
}

.disabled {
  color: #4a5463 !important;
}

.option-label {
  width: auto !important;
  height: auto !important;
}