.analysis-result-header-container {
  display: flex;
  width: 100%;
  padding: 11px 13px;
  align-items: center;
  gap: 0.9375rem;
  background: var(--shades-white, #fff);
  border-radius: 0.5rem 0.5rem 0rem 0rem;
  height: 55px;
}

.analysis-result-header-container-collapsed {
  border-radius: 0.5rem;
}

.analysis-result-button {
  display: flex;
  transform: rotate(-0.11deg);
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.25rem;
  background: var(--primary-indigo-700, #005f7c);
}

.analysis-result-image {
  stroke: white;
}

.analysis-result-title-text {
  flex: 1 0 0;
  color: var(--primary-indigo-700, #60bfda) !important;

  /* Paragraph 2 [P2]/Semibold */
  font-size: 1.1rem;
  font-style: normal;
  font-weight: var(--font-weight-bolder);
  line-height: 1.5rem;
}

.analysis-result-description {
  color: var(--Neutral-Grey-700, var(--Neutral-Grey700, #323f46));
  text-align: right;

  font-size: 12px;
  font-weight: var(--font-weight-medium);
  line-height: 18px;
}

.theme-dark .analysis-result-header-container {
  background: transparent;
}

.gl-analysis-result-title-text {
  color: #f7f9f9;

  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
}
