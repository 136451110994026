.splash-shell {
  background: linear-gradient(180deg, #353f4f, #131f2e);
  justify-content: center;
  height: 100vh;
  width: 100%;
  position: relative;

  .content {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  p.text {
    text-align: center;
    font-size: 20px;
    color: #fff;
    padding-top: 2rem;
  }
}